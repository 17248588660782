import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { LANDING_LEARN } from "../../../constants/routePaths";
import "./RequestAccessBox.scss";

interface RequestAccessBoxProps {
  handleClicked: () => void;
}

const RequestAccessBox: FC<RequestAccessBoxProps> = ({ handleClicked }) => {
  const { t } = useTranslation();

  return (
    <div>
      <div className="landing-request-access-box-title" tabIndex={0}>
        {t("landing.request-access-box.title")}
      </div>
      <div className="font-weight-light" tabIndex={0}>
        {t("landing.request-access-box.body")}
        <Link
          to={LANDING_LEARN}
          title={t("home.main.banner.button")}
          className="font-weight-bold"
          aria-label={t("home.main.banner.button")}
        >
          ({t("home.main.banner.button")})
        </Link>
        {t("landing.request-access-box.body1")}
      </div>
      <div className="landing-request-access-box-button-container">
        {/*
        <button
          onClick={handleClicked}
          className="btn btn-block td-btn-primary-light landing-request-access-btn"
          type="button"
        >
          {t("landing.request-access-box.button")}
        </button>
        */}
      </div>
    </div>
  );
};

export default RequestAccessBox;
