import {FC} from "react";
import "./Footer.scss";
import iirocLogo from "../../../assets/images/CIRO-Logo-Dark-Desktop-EN.png";
import iirocLogoFr from "../../../assets/images/CIRO-Logo-Dark-Desktop-FR.png";
import cipfLogo from "../../../assets/images/Footer-CIPF-Logo-EN.png";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import {useAuth} from "../../../auth/AuthProvider";
import {CONTACT_US, LANDING_CONTACT_US} from "../../../constants/routePaths";

interface Props {
}

const Footer: FC<Props> = () => {
  const { t, i18n } = useTranslation();
  let { authenticated } = useAuth();

  const isEnglish = () => {
    return i18n.language.startsWith("en");
  };

  return (
    <>
      <footer>
        <div className="tds-footer-divider"></div>
        <div className="tds-container">
          <div className="tds-footer-contact-us">
              <Link
                to={authenticated ? CONTACT_US : LANDING_CONTACT_US}
                title={t("contact_us")}
                className="tds-footer-contact-us-text"
                >
                  {t("contact_us")}
              </Link>
          </div>
          <div className="tds-footer-links">
            <ul>
              <li>
                <a
                    href={
                      isEnglish()
                          ? "https://www.tdsecurities.com/ca/en/privacy-security"
                          : "https://www.tdsecurities.com/ca/fr/privacy-security"
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                    className="tds-footer-link"
                    aria-label={(t("privacy_security") + " " + t("link.open.in.new.tab.text"))}
                >
                  {t("privacy_security")}
                </a>
              </li>
              <li>
                <a
                    href={
                      isEnglish()
                          ? "https://www.tdsecurities.com/ca/en/legal"
                          : "https://www.tdsecurities.com/ca/fr/legal"
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                    className="tds-footer-link"
                    aria-label={(t("legal") + " " + t("link.open.in.new.tab.text"))}
                >
                  {t("legal")}
                </a>
              </li>
              <li>
                <a
                    href={
                      isEnglish()
                          ? "https://www.tdcanadatrust.com/customer-service/accessibility/accessibility-at-td/index.jsp"
                          : "https://www.td.com/ca/fr/a-propos-de-la-td/accessibilite"
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                    className="tds-footer-link"
                    aria-label={(t("accessibility") + " " + t("link.open.in.new.tab.text"))}
                >
                  {t("accessibility")}
                </a>
              </li>
              <li>
                <a
                    href={
                      isEnglish()
                          ? "https://www.tdsecurities.com/ca/en/global-disclaimer"
                          : "https://www.tdsecurities.com/ca/fr/global-disclaimer"
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                    className="tds-footer-link"
                    aria-label={(t("global_disclaimer") + " " + t("link.open.in.new.tab.text"))}
                >
                  {t("global_disclaimer")}
                </a>
              </li>
              <li>
                  {/* link translation is done by the OneTrust JS script*/}
                  <a className="ot-sdk-show-settings tds-footer-link" tabIndex={0} href="/#" onMouseDown={(e) => {
                    e.preventDefault();
                    // @ts-ignore
                    if (window.OneTrust) {window.OneTrust.ToggleInfoDisplay();}
                  }
                  }>Manage online experience</a>
              </li>
            </ul>
          </div>
          <div className="tds-footer-logos">
            <a
              href="https://www.ciro.ca/"
              title={t("iiroc_logo")}
              target="_blank"
              rel="noopener noreferrer"
              aria-label={(t("iiroc_logo") +" "+ t("link.open.in.new.tab.text"))}
            >
              <img src={isEnglish() ? iirocLogo : iirocLogoFr } alt={t("iiroc_logo")} />
            </a>
            <a
              href="https://www.cipf.ca/"
              title={t("cipf_logo")}
              target="_blank"
              rel="noopener noreferrer"
              aria-label={(t("cipf_logo") +" "+ t("link.open.in.new.tab.text"))}
            >
              <img
                src={cipfLogo}
                className="td-rte-margin-top-none"
                alt={t("cipf_logo")}
              />
            </a>
          </div>
          <div className="tds-footer-note">
            <span tabIndex={0}>{t("landing.header.footer_note.1")}{" "}</span>
            <a
              href="https://www.sipc.org/"
              target="_blank"
              rel="noopener noreferrer"
              aria-label={("SIPC "+ t("link.open.in.new.tab.text"))}
            >
              SIPC
            </a>{" "}
            <span tabIndex={0}>{t("landing.header.footer_note.2")}{" "}</span>
            <a
              href="https://www.finra.org/"
              target="_blank"
              rel="noopener noreferrer"
              aria-label={("FINRA "+ t("link.open.in.new.tab.text"))}
            >
              FINRA
            </a>{" "}
            <span tabIndex={0}>{t("landing.header.footer_note.3")}{" "}</span>
            <a
              href="https://brokercheck.finra.org/"
              target="_blank"
              rel="noopener noreferrer"
              aria-label={("BrokerCheck "+ t("link.open.in.new.tab.text"))}
            >
              BrokerCheck
            </a>
            {t("landing.header.footer_note.4")}
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
