import React, { FC } from "react";
import "./CategoryContent.scss";
import CategoryItem from "./CategoryItem";
import { ApplicationStatus } from "../../../data/apiData";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { REGISTRATION } from "../../../constants/routePaths";

export interface CategoryContentProps {
  title?: string;
  items: CategoryContentItem[];
  showTitle?: boolean;
}

export interface CategoryContentItem {
  status: ApplicationStatus;
  userHasAccess: boolean;
  requestInProcess: boolean;
  name: string;
  description: string;
  link: string;
  target: string;
  imageUrl: string;
  authenticated: boolean;
}

const CategoryContent: FC<CategoryContentProps> = ({
  title,
  items,
  showTitle = true,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const getButtonActionText = (item: CategoryContentItem) => {
    if (item.status === ApplicationStatus.FULLY_ONBOARDED) {
      if (!item.authenticated) {
        return t("home.app.type.login");
      }

      if (item.userHasAccess) {
        return t("home.app.type.has-access");
      } else if (item.requestInProcess) {
        return t("home.app.type.request.in.process");
      } else {
        return t("home.app.type.no-access");
      }
    }
    if (item.status === ApplicationStatus.ONBOARDED_SSO_ONLY_APP) {
      if (!item.authenticated) {
        return t("home.app.type.login");
      }

      if (item.userHasAccess) {
        return t("home.app.type.has-access");
      } else {
        return t("home.app.type.no-access");
      }
    }
    if (
      item.status === ApplicationStatus.EXTERNAL_LOGIN ||
      item.status === ApplicationStatus.EXTERNAL_APP_TO_BE_ONBOARDED
    ) {
      return t("home.app.type.external-login");
    }
    if (item.status === ApplicationStatus.OPEN_APP) {
      return t("home.app.type.open-app");
    }

    return "Not Supported";
  };
  const handleOnClick = (
    name: string,
    link: string,
    target: string,
    appStatus: ApplicationStatus,
    userHasAccess: boolean,
    authenticated: boolean
  ) => {
    if (
      !userHasAccess &&
      appStatus === ApplicationStatus.FULLY_ONBOARDED &&
      authenticated
    ) {
      navigate(REGISTRATION, { state: { appName: name } });
    } else {
      window.open(link, target);
    }
  };

  const listItems = items.map((item: CategoryContentItem, id: number) => {
    return (
      <CategoryItem
        item={item}
        id={id}
        onClicked={handleOnClick}
        key={id}
        actionText={getButtonActionText(item)}
        buttonVisible={
          !(
            item.authenticated &&
            item.status === ApplicationStatus.ONBOARDED_SSO_ONLY_APP &&
            !item.userHasAccess
          )
        }
      />
    );
  });

  return (
    <div className="tds-three-column-container">
      <div
        className={`tds-three-column-title-container ${
          showTitle ? "" : "no-title-category-card"
        } `}
      >
        {showTitle && (
          <h2 aria-label={title} tabIndex={0}>
            {title}
          </h2>
        )}
      </div>
      {listItems}
    </div>
  );
};

export default CategoryContent;
